// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-10-js": () => import("./../../../src/pages/10.js" /* webpackChunkName: "component---src-pages-10-js" */),
  "component---src-pages-10-lista-js": () => import("./../../../src/pages/10lista.js" /* webpackChunkName: "component---src-pages-10-lista-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-8-lista-js": () => import("./../../../src/pages/8lista.js" /* webpackChunkName: "component---src-pages-8-lista-js" */),
  "component---src-pages-buy-10-index-js": () => import("./../../../src/pages/buy10/index.js" /* webpackChunkName: "component---src-pages-buy-10-index-js" */),
  "component---src-pages-buy-10-ticket-form-js": () => import("./../../../src/pages/buy10/TicketForm.js" /* webpackChunkName: "component---src-pages-buy-10-ticket-form-js" */),
  "component---src-pages-buy-index-js": () => import("./../../../src/pages/buy/index.js" /* webpackChunkName: "component---src-pages-buy-index-js" */),
  "component---src-pages-buy-ticket-form-js": () => import("./../../../src/pages/buy/TicketForm.js" /* webpackChunkName: "component---src-pages-buy-ticket-form-js" */),
  "component---src-pages-buydescuento-index-js": () => import("./../../../src/pages/buydescuento/index.js" /* webpackChunkName: "component---src-pages-buydescuento-index-js" */),
  "component---src-pages-buydescuento-ticket-form-js": () => import("./../../../src/pages/buydescuento/TicketForm.js" /* webpackChunkName: "component---src-pages-buydescuento-ticket-form-js" */),
  "component---src-pages-data-js": () => import("./../../../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-descuentos-js": () => import("./../../../src/pages/descuentos.js" /* webpackChunkName: "component---src-pages-descuentos-js" */),
  "component---src-pages-epk-js": () => import("./../../../src/pages/epk.js" /* webpackChunkName: "component---src-pages-epk-js" */),
  "component---src-pages-goma-5-js": () => import("./../../../src/pages/goma5.js" /* webpackChunkName: "component---src-pages-goma-5-js" */),
  "component---src-pages-goma-7-[id]-index-js": () => import("./../../../src/pages/goma7/[id]/index.js" /* webpackChunkName: "component---src-pages-goma-7-[id]-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mpapproved-9-index-js": () => import("./../../../src/pages/mpapproved9/index.js" /* webpackChunkName: "component---src-pages-mpapproved-9-index-js" */),
  "component---src-pages-mpapproved-9-ticket-js": () => import("./../../../src/pages/mpapproved9/Ticket.js" /* webpackChunkName: "component---src-pages-mpapproved-9-ticket-js" */),
  "component---src-pages-mpapproved-index-js": () => import("./../../../src/pages/mpapproved/index.js" /* webpackChunkName: "component---src-pages-mpapproved-index-js" */),
  "component---src-pages-mpapproved-ticket-js": () => import("./../../../src/pages/mpapproved/Ticket.js" /* webpackChunkName: "component---src-pages-mpapproved-ticket-js" */)
}

