import React, {useEffect, useState} from 'react';
import {
    collection,
    getDocs,
    getFirestore,
    query,
    where,
    updateDoc,
    doc
} from "firebase/firestore";
import fb from "gatsby-plugin-firebase-v9.0";

let httpsCallable, getFunctions;

if (typeof window !== 'undefined') {
    ({ getFunctions, httpsCallable } = require("firebase/functions"));
}

const Lista = () => {
    const firestore = getFirestore(fb)
    const eventRef = collection(firestore, '/goma#10');
    const [users, setUsers] = useState([])

    const toggleEmailSent = async u => {
        await updateDoc(doc(eventRef, u.id), {
            confirmationEmailSent: !u.confirmationEmailSent
        });

        getLista()
    }

    const getLista = async () => {

        const q = query(eventRef, where('ticketStatus', '==', "approved"))
        const users = await getDocs(q)

        setUsers(users.docs.map(u => {
            return {...u.data(), id: u.id}
        }))
    }

    useEffect(() => {
        getLista()
    }, [])


    const sendEmail = (u) => {
        const functions = getFunctions(fb)
        const sendEmaile = httpsCallable(functions, 'sendEmail10');

        sendEmaile(u)
    }

    return (
        <div style={{
            fontSize: 24
        }}>
            <p>{users.length} boletos vendidos</p>
            <br/>
            <br/>
            <table style={{width: '100%'}}>
                <tr style={{border: '1px solid white'}}>
                    <th style={{border: '1px solid white'}}>ID</th>
                    <th style={{border: '1px solid white'}}>EMAIL</th>
                    <th style={{border: '1px solid white'}}>NAME</th>
                    <th style={{border: '1px solid white'}}>EMAIL SENT?</th>
                    <th style={{border: '1px solid white'}}>SEND EMAIL</th>
                </tr>
                {users.sort((a, b) => a.email.localeCompare(b.email)).map(u => {
                    return (
                        <tr style={{border: '1px solid white'}}>
                            <td style={{border: '1px solid white'}}>{u.id}</td>
                            <td style={{border: '1px solid white'}}>{u.email}</td>
                            <td style={{border: '1px solid white'}}>{u.firstName} {u.lastName}</td>
                            <td
                                style={{border: '1px solid white'}}
                                onClick={() => toggleEmailSent(u)}
                            >
                                {u?.confirmationEmailSent ? 'chi' : 'ño'}
                            </td>
                            <td
                                style={{border: '1px solid white'}}
                                onClick={() => sendEmail(u)}
                            >
                                <span className="link">
                                    send email
                                </span>
                            </td>
                        </tr>
                    )
                })}
            </table>
        </div>
    );
};

export default Lista;