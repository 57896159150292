import React from "react"
import Layout from "../components/layout"
import Link from "gatsby-link";
import Body from "../components/body";

const EPK = () => {
    return (
        <Layout>
            <Body>
                <div style={{display:'flex', flexDirection: 'column'}}>
                    <p>goma#05 - press release</p>
                </div>
            </Body>
        </Layout>
    )
}

export default EPK

