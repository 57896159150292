import React from "react";

export const InvalidPayment = () => {
    return (
        <>
            <p>
                Este pago no fue realizado correctamente, por favor contactate con
                @goma.mx en instagram o gomagoma.mx@gmail.com
            </p>
        </>
    )
}

