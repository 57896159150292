import React from "react"
import Layout from "../components/layout"
import Link from "gatsby-link";
import Body from "../components/body";
import Mantenimiento from "../components/mantenimiento";

const Index = () => {
    return (
        <Layout>
            <Body>
                <div style={{display:'flex', flexDirection: 'column'}}>
                    <p>ERES AMIGX DE GOMA, TE DAMOS UN DESCUENTO !! :)</p>
                    <p>goma#10</p>
                    <p>fecha: 11/11/23</p>
                    <p>line up: Xiu Xiu, Torso Corso, Rodrigo Ambriz + German Bringas + Gibrán Andrade</p>
                    <p>lugar: Residencia Obrera</p>
                    <p>precio (descuentos): $450 (+ cargos)</p>
                    <br/>
                    <Link to="/buydescuento" className="link">
                        COMPRAR BOLETOS
                    </Link>
                </div>
            </Body>
        </Layout>
    )
}

export default Index

