import React from 'react';
import Body from "./body";
import Layout from "./layout";

const Mantenimiento = () => {
    return (
        <Layout>
            <Body>
                <div>
                    la pagina esta bajo mantenimiento <br/>
                    (๑•́ㅿ•̀๑), vuelve en 1 hora !!
                </div>
            </Body>
        </Layout>
    );
};

export default Mantenimiento;